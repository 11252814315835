module services {
    export module master {
        export class productCostGroupService implements interfaces.master.IProductCostGroupService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            //Gets list of all Product Types for a dropdown/autocomplete list
            getDropdownList(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "ProductCostGroup/GetDropdownList", {
                        ownerEntityId: '@ownerEntityId'
                    }, {
                            query: {
                                method: 'GET',
                                isArray: true
                            }
                        });
            }
        }
    }
    angular.module("app").service("productCostGroupService", services.master.productCostGroupService);
}